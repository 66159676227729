<template>
  <vca-row>
    <div>
      <h3>{{ $t("expense.list.status_filter.header") }}</h3>
    </div>
  </vca-row>
</template>
<script>
export default {
  name: "ExpenseFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>
